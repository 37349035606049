import type { DialogueNodeData } from '@common/studio-types';
import type { StatefulGameConfig } from '../createGame/statefulGame';
import type { GameState } from '../game';
import type { PlayerData } from '../game/player';
import type { GameNode } from '../studioGameCreator.types';
import type { ActionResult } from './actions.types';
import { convertToText } from './utils';

export const dialogue = async (
  block: GameNode<DialogueNodeData>,
  state: GameState,
  playerData: PlayerData,
  config: StatefulGameConfig,
): Promise<ActionResult> => {
  const characterName = await convertToText(
    block.characterName,
    state,
    config,
    playerData,
  );
  const message = await convertToText(block.message, state, config, playerData);
  const action = await convertToText(block.action, state, config, playerData);

  return {
    messages: [
      {
        nodeId: block.id,
        type: 'dialogue',
        data: {
          action,
          message,
          characterName,
          characterImage: block.characterImage,
          characterVoiceId: block.characterVoiceId,
          direction: block.direction,
        },
      },
    ],
    haltExecution: true,
    state,
  };
};
